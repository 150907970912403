<template>
    <div class="noauth">
        <div class="valign-wrapper" style="height:500px;">
            <div class="valign">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 24 24" fill="#f44336"><path fill="none" d="M0 0h24v24H0V0z"/><path opacity=".3" d="M9.1 5L5 9.1v5.8L9.1 19h5.8l4.1-4.1V9.1L14.9 5H9.1zM12 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm1-3h-2V7h2v7z"/><path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z"/><circle cx="12" cy="16" r="1"/><path d="M11 7h2v7h-2z"/></svg>
                    <h2>Access Denied</h2>
                    <h5 class="light">You are not authorized to access this page</h5>
                    <br/>
                    <br/>
                    <a v-if="user.role === 'Affiliation Head'" :href="`${baseUrl}/AffiliationDashBoard.aspx`" class="btn">Go Home</a>
                    <a v-if="user.role === 'Faculty'" :href="`${baseUrl}/ManageUsers.aspx`" class="btn">Go Home</a>
                    <a v-if="user.role.includes('Student')" :href="`${baseUrl}/StudentDashboardNew.aspx`" class="btn">Go Home</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      baseUrl: "",
    };
  },
  activated() {
    this.baseUrl = this.user.DomainName;
  },
  computed: {
    ...mapGetters([
      "user",
    ]),
  },
};
</script>

<style scoped>
.noauth {
    padding: 50px 0;
}
</style>
